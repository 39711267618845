import type { AdditionalUserInfo, IdTokenResult, User } from "firebase/auth";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import qs from "query-string";
import Cookies from "js-cookie";

import { onSinedInButtonClick } from "@rimo/frontend/services/conversion/Google";
import Sentry from "../../../utils/sentry";
import { onSignUp } from "./event";
import { createFirebaseAuthenticateErrorMessage } from "./error";
import { signUpConversion } from "../../conversion/Conversion";
import userApi from "@rimo/frontend/api/user";
import { cookieKeyAffiliate } from "@rimo/frontend/hooks/useAffiliate";
import type { UserExtraInfoAuthResult } from "./user";

export const QueryStringSamlProviderIdKey = "samlProviderId";

export async function signUpWithEmailAndPassword(
  email: string,
  password: string,
  options: { lastName: string; firstName: string },
  onComplete?: ((user: User) => Promise<void>) | undefined
): Promise<UserExtraInfoAuthResult> {
  try {
    // todo ここは本当に追加してもOK?
    // const validateResult = await validateEmailWithError(email, "email");
    // if (validateResult instanceof Error) throw validateResult;

    // todo サインイン(ログイン)用のものを呼び出している気がする
    onSinedInButtonClick("email");

    const userName = `${options.lastName} ${options.firstName}`;
    const credential = await createUserWithEmailAndPassword(getAuth(), email, password);
    const result = await onSignUp(credential, { userName });
    await onComplete?.(result.userExtra.user);

    return result;
  } catch (err) {
    const defaultError = "メールアドレスでのユーザー登録処理中に不明なエラーが発生しました。";
    const [error, isFirebaseError] = createFirebaseAuthenticateErrorMessage(err, defaultError);
    if (!isFirebaseError) Sentry.captureException(err);
    console.warn(`[Firebase Authentication] createUserWithEmailAndPassword(email="${email}")`, err);
    throw error;
  }
}

export async function notifySignUp(
  user: User,
  additional: AdditionalUserInfo | null,
  idToken: IdTokenResult
): Promise<boolean> {
  try {
    const providerId = additional?.providerId ?? idToken.signInProvider;
    const locationSearch = getClientSideAffiliateLocationSearch();

    const signUpSent = await userApi.notifyLogin(locationSearch, idToken.token);

    if (signUpSent === false && providerId) {
      await signUpConversion(providerId, user.uid);
      return true;
    }

    if (!providerId) {
      const e = new Error("can't find providerId value user used for signedUp");
      Sentry.captureException(e, { extra: { user } });
    }

    return false;
  } catch (err) {
    Sentry.captureException(err, { extra: { user } });
    return false;
  }
}

// クエリーパラメータを連想配列に置換し、router.push()等で再利用しやすくする
export function copyQueryStringForAdvertisement() {
  // Providerを使ってサインイン(サインアップ)する前にクエリーパラメータをコピーする
  // utm等の広告関連の情報が埋め込まれているため、ページ遷移でデータが消えないようにする
  const searchParams = new URLSearchParams(window.location.search);
  const init: { [key in string]: string } = {};
  const data = [...searchParams].reduce((prev, cur) => {
    const [key, value] = cur;
    if (!key || typeof key !== "string") {
      return prev;
    } else {
      prev[key] = value ?? ""; // 空文字でもkeyデータだけでも残す
      return prev;
    }
  }, init);

  return data;
}

/**
 * アフィリエイトコードをクエリーパラメータ or クッキーから取得し、それを合成したクエリーパラメータを新規作成する
 */
function getClientSideAffiliateLocationSearch(): string {
  if (typeof window === "undefined" || typeof document === "undefined") return "";

  const parsed = qs.parse(window.location.search);

  // Samlに関する情報は送信しない
  const samlProviderId = parsed[QueryStringSamlProviderIdKey];
  if (samlProviderId) parsed[QueryStringSamlProviderIdKey] = "";

  // クエリーパラメータ
  const a8Query = parsed["a8"];
  const a8QueryIncluded = typeof a8Query === "string" && Boolean(a8Query);

  // クッキー
  const a8CookieValue = Cookies.get(cookieKeyAffiliate);

  if (!a8QueryIncluded && a8CookieValue) parsed["a8"] = a8CookieValue;

  return "?" + qs.stringify(parsed);
}
