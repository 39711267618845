import type { Organization, Team } from "../api-client/models";
import { client } from "./";

// Model
export type Invitation = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  created_at: Date;
  invitor_orgs: Organization[];
};

export default {
  async get(): Promise<Invitation | null> {
    const resp = await client.get<Invitation | null>("/voice/invitations");
    return resp.data;
  },

  async accept(organizationId: string, token?: string | undefined): Promise<Team> {
    const resp = await client.post<Team>(
      `/organizations/${organizationId}/invitations/accept`,
      undefined,
      !token
        ? undefined
        : {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
    );

    return resp.data;
  },

  decline(organizationId: string): Promise<void> {
    return client.post(`/organizations/${organizationId}/invitations/decline`);
  },
};
