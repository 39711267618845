import type { AxiosError } from "axios";
import { client } from ".";
import type { VoiceInfoResponse } from "./voice";

export type AdminPermission = "viewer" | "editor" | "plan.viewer" | "plan.editor";
export type AdminRoleName = "AdminRoleViewer" | "AdminRoleEditor" | "AdminRolePlanEditor" | "AdminRolePlanViewer";

export type AdminRole = {
  name: AdminRoleName;
  permissions: AdminPermission[];
};

export default {
  async signUp(idToken: string): Promise<VoiceInfoResponse> {
    const response = await client
      .post<VoiceInfoResponse>(
        "/voice/signup",
        {},
        {
          headers: {
            // apply jwt manually because axios interceptors doesn't setup yet
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch((e: AxiosError) => {
        throw e;
      });

    if (response.status !== 200) throw new Error("Occurred Network error");

    return response.data;
  },

  async notifyLogin(locationSearch: string, idToken: string): Promise<boolean> {
    const locale = navigator.language;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = new URLSearchParams(locationSearch);
    params.set("locale", locale);
    params.set("timezone", timeZone);
    const searchParams = params.toString() ? "?" + params.toString() : "";
    const response = await client.get<{ sign_up_sent: boolean }>(`/notify/user/login${searchParams}`, {
      headers: {
        // apply jwt manually because axios interceptors doesn't setup yet
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response.data.sign_up_sent;
  },

  async getUserRoles(userId: string): Promise<AdminRole[] | undefined> {
    const resp = await client.get<AdminRole[]>(`/users/${userId}/roles`).catch((e) => {
      console.error(e);
      return undefined;
    });
    return resp?.data;
  },
};
