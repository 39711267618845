import type { AuthProvider, IdTokenResult, User } from "firebase/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithRedirect,
  signInWithCustomToken,
  getAuth,
  signInWithEmailAndPassword as signInFirebase,
} from "firebase/auth";

import LoginAPI from "@rimo/frontend/api/login";
import Sentry from "@rimo/frontend/utils/sentry";

import invitationApi from "../../../api/invitation";
import { onSinedInButtonClick } from "../../conversion/Google";
import { SessionStorage } from "../../storage/SessionStorage";
import { createFirebaseAuthenticateErrorMessage } from "./error";
import { onSignIn } from "./event";
import type { UserExtraInfoAuthResult } from "./user";
import type { Team } from "@rimo/frontend/api-client/models";

// todo ProviderIdに切り替えたい
export type SNS = "google" | "microsoft" | "facebook" | "saml";

export function isSNS(v: unknown): v is SNS {
  return typeof v === "string" && ["google", "microsoft", "facebook", "saml"].includes(v);
}

/**
 * ソーシャルログイン機能を使ったログイン・サインアップ処理(ProviderId=dynamic)
 * @param service SNS
 * @param samlId SAMLログイン時のID値
 */
export async function signInWithSocial(service: SNS, samlId?: string | undefined) {
  if (!isSNS(service)) throw new Error(`not found match social service: ${service}`);

  let provider: AuthProvider | null = null;

  if (service === "google") {
    const p = new GoogleAuthProvider();
    p.setCustomParameters({ prompt: "select_account" });
    provider = p;
  } else if (service === "facebook") {
    const p = new FacebookAuthProvider();
    p.addScope("email");
    provider = p;
  } else if (service === "microsoft") {
    const p = new OAuthProvider("microsoft.com");
    provider = p;
  } else if (service === "saml" && samlId) {
    const p = new SAMLAuthProvider(samlId);
    provider = p;
  } else {
    throw new Error("You must apply specific Provider value");
  }

  // ソーシャルログインの為に、リダイレクトを実行する
  if (provider) {
    onSinedInButtonClick(service);
    await signInWithRedirect(getAuth(), provider);
  }
}

/**
 * ProviderId="password" でのログインを実施する
 * @param email メールアドレス
 * @param password パスワード
 * @returns UserExtraInfoAuthResult
 */
export async function signInWithEmailAndPassword(
  email: string,
  password: string,
  onComplete?: ((user: User) => Promise<void>) | undefined
): Promise<UserExtraInfoAuthResult> {
  try {
    const resp = await LoginAPI.validateEmail(email).catch((err: Error) => {
      throw new Error("入力されたメールアドレスの認証チェック中にエラーが発生しました: " + err.message);
    });
    if (resp.notExists) {
      throw new Error("入力されたメールアドレスは登録されていません");
    }
    if (!resp.passwordLoginAcceptable) {
      throw new Error("このメールアドレスはこのログイン方法が使えません");
    }
  } catch (e) {
    Sentry.captureException(e, { extra: { email, backend_url: "/email/validate", phase: "sign_in" } });
    throw e; // エラーハンドラは呼び出し元でやっている
  }

  try {
    const credential = await signInFirebase(getAuth(), email, password);
    const result = await onSignIn(credential);
    await onComplete?.(result.userExtra.user);
    return result;
  } catch (err) {
    throw handleSignInWithEmailAndPasswordError(err, email);
  }
}

/**
 * サインイン時に招待用メール内の招待リンクからのログインがあると自動的に承認処理をする
 * */
export async function autoAcceptOrgInvitationWithSessionStorage(idToken: IdTokenResult): Promise<Team | undefined> {
  try {
    const data = SessionStorage.getItem("invitationConfirming");
    const orgIdInvited = typeof data === "string" ? data : null;
    const token = idToken.token;

    // セッションストレージに招待企業IDがなければ処理はしない
    if (!orgIdInvited) return undefined;

    // 実際の承認処理(今の所レスポンス変数は通知UIに利用しているだけ)
    const team = await invitationApi.accept(orgIdInvited, token);

    return team;
  } catch (err) {
    // 例外が発生下としても、Rimo Voice上で招待のアラートからユーザー自身で招待を承認が可能な為、エラーを握りつぶす
    Sentry.captureException(err);
    return undefined;
  } finally {
    SessionStorage.setItem("invitationConfirming", null);
  }
}

export const handleSignInWithEmailAndPasswordError = (err: unknown, email: string): Error => {
  const defaultError = "メールアドレスでのログイン処理中に不明なエラーが発生しました。";
  const [error, isFirebaseError] = createFirebaseAuthenticateErrorMessage(err, defaultError);

  if (!isFirebaseError) Sentry.captureException(err);

  console.warn(`[Firebase Authentication] signInWithEmailAndPassword(email="${email}")`, err);

  return error;
};

/**
 * @param token カスタムトークン
 * @returns UserExtraInfoAuthResult
 */
export async function signInWithUserToken(
  token: string,
  onComplete?: ((user: User) => Promise<void>) | undefined
): Promise<UserExtraInfoAuthResult> {
  try {
    const credential = await signInWithCustomToken(getAuth(), token);
    const result = await onSignIn(credential);
    await onComplete?.(result.userExtra.user);
    return result;
  } catch (err) {
    throw handleSignInWithUserTokenError(err, token);
  }
}

export const handleSignInWithUserTokenError = (err: unknown, token: string): Error => {
  const defaultError = "ユーザートークンでのログイン処理中に不明なエラーが発生しました。";
  const [error, isFirebaseError] = createFirebaseAuthenticateErrorMessage(err, defaultError);

  if (!isFirebaseError) Sentry.captureException(err);

  console.warn(`[Firebase Authentication] signInWithUserToken(token="${token}")`, err);

  return error;
};
